
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';

import UserService, { UserServiceS } from '@/modules/user/user.service';
import OpenTelemetryService, { OpenTelemetryServiceS } from '@/modules/open-telemetry/open-telemetry.service';
import { LOGTYPE } from '@/modules/open-telemetry/constants';

import Dialog from '@/modules/common/components/ui-kit/dialog.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import TabsController from '@/modules/common/components/tabs-controller.vue';
import ReportPropertiesTab from '../components/tabs/reports-properties.tab.vue';
import IntradaysPropertiesTab from '../components/tabs/intradays-properties.tab.vue';
import ScanPropertiesTab from '../components/tabs/scan-properties.tab.vue';
import AlertsAndNotificationsTab from '../components/tabs/alerts-notifications.tab.vue';
import ColorPalette from '../components/tabs/color-palette.tab.vue';
import DefaultFiltersTab from '../components/tabs/default-filters.tab.vue';

@Component({
    components: {
        PageWrapper,
        TabsController,
        Dialog,
    },
})
export default class SettingsPage extends Vue {
    @inject(UserServiceS) private userService!: UserService;
    @inject(OpenTelemetryServiceS) private otelService!: OpenTelemetryService;

    settingsMode = 'general';
    currentTab = 0;

    tabsMap = [
        ScanPropertiesTab,
        IntradaysPropertiesTab,
        AlertsAndNotificationsTab,
        ReportPropertiesTab,
        ColorPalette,
        DefaultFiltersTab,
    ];

    beforeMount() {
        this.currentTab = +this.$route.query.tab || 0;
        this.updateQueryParameter();
        this.otelService.startSpan({ name: this.$route.name!, prefix: LOGTYPE.VISITPAGE });
    }

    get tabs() {
        const { isAdmin } = this.userService;
        return this.tabsMap
            .filter(tabComponent => {
                const solveShowing = 'showSolver' in tabComponent
                    ? tabComponent.showSolver
                    : null;

                if (!isAdmin && tabComponent.adminOnly) {
                    return false;
                }

                if (!solveShowing) return true;

                return solveShowing.bind(this)();
            });
    }

    get isViewAsHotel() {
        return this.userService.isViewAsHotel;
    }

    async setTab(index: number) {
        if (await this.onTabLeavePrevented()) {
            return;
        }

        this.currentTab = index;
        this.updateQueryParameter();

        const name = `settings-${this.tabs[this.currentTab].title.toLowerCase().split(' ').join('-')}-${this.settingsMode}`;
        this.otelService.startSpan({ name, prefix: LOGTYPE.VISITPAGE });
    }

    async toHotel() {
        if (await this.onTabLeavePrevented()) {
            return;
        }

        this.currentTab = 0;
        this.$router.push({
            name: this.$route.name!.replace('cluster.', 'hotel.'),
            params: {
                hotelId: String(this.userService.currentHotelId),
            },
            query: {
                tab: String(this.currentTab),
            },
        });
    }

    private async onTabLeavePrevented() {
        const { title: tabTitle = '' } = this.tabs[this.currentTab] || {};
        const name = `settings-${tabTitle.toLowerCase().split(' ').join('-')}-${this.settingsMode}`;

        this.otelService.endSpan({ name, prefix: LOGTYPE.VISITPAGE }, { sendLogs: true });

        const tabComponent = this.tabs[this.currentTab];

        if (!tabComponent) return false;

        if (tabComponent.onTabLeave) {
            let result = tabComponent.onTabLeave();

            if (result instanceof Promise) {
                result = await result;
            }

            return !result;
        }

        return false;
    }

    private updateQueryParameter() {
        const isTabChanged = this.currentTab !== +this.$route.query.tab;
        if (!isTabChanged) return;

        this.$router.push({
            name: this.$route.name!,
            params: {
                ...this.$route.params,
            },
            query: {
                tab: String(this.currentTab),
            },
        });
    }
}
